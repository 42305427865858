import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin);

const locomotiveScroll = new LocomotiveScroll({
  lenisOptions: {
    wheelMultiplier: 0.000000000000000000000001,
  },
});

const sizes = { width: window.innerWidth, height: window.innerHeight }
let sections = sizes.width >= 1000 ? ['#pri', '#seg', '#ter', '#cua', '#qui'] : ['#pri', '#seg', '#var', '#ter', '#cua', '#qui'];
let currentSection = Math.round(window.scrollY / sizes.height);
let isScrolling = false;

const move = { start: 0, finish: 0 };

// Nav Buttons Movement
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    gsap.to(window, {
      duration: 0.8,
      scrollTo: document.querySelector(this.getAttribute('href')),
    });
  });
});

// Touch Movement
window.addEventListener('touchstart', (e) => {
  move.start = e.touches[0].clientY;
});

window.addEventListener('touchmove', (e) => {
  e.preventDefault();
}, { passive: false });

window.addEventListener('touchend', (e) => {
  move.finish = move.start - e.changedTouches[0].clientY;
  sections = sizes.width >= 1000 ? ['#pri', '#seg', '#ter', '#cua', '#qui'] : ['#pri', '#seg', '#var', '#ter', '#cua', '#qui'];
  currentSection = Math.round(window.scrollY / sizes.height)

  if (move.finish > 0) {
    if (currentSection < sections.length - 1) currentSection++;
  } else if (move.finish < 0) {
    if (currentSection > 0) currentSection--;
  }

  gsap.to(window, {
    duration: 0.8,
    scrollTo: currentSection * sizes.height,
  });
});

window.addEventListener('wheel', function (event) {
  if (isScrolling) return;

  isScrolling = true;
  sections = sizes.width >= 1000 ? ['#pri', '#seg', '#ter', '#cua', '#qui'] : ['#pri', '#seg', '#var', '#ter', '#cua', '#qui'];
  currentSection = Math.round(window.scrollY / sizes.height)

  if (event.deltaY > 0) {
    if (currentSection < sections.length - 1)
      currentSection++;
  } else if (event.deltaY < 0) {
    if (currentSection > 0) currentSection--;
  }

  gsap.to(window, {
    duration: 0.8,
    scrollTo: currentSection * sizes.height,
    onComplete: function () {
      setTimeout(function () {
        isScrolling = false;
      }, 1250); // Agrega un retraso antes de permitir otro desplazamiento
    },
  });
});

if (sizes.width >= 1000)
  document.getElementById('regcont').style.height = `${sizes.height / 1.5}px`;

window.addEventListener('resize', () => {
  if (sizes.width >= 1000)
    document.getElementById('regcont').style.height = `${sizes.height / 1.5}px`;

  sizes.width = window.innerWidth
  sizes.height = window.innerHeight
})

// Textos Afuera
gsap.to('#tex1', { y: 100, opacity: 0 });
gsap.to('#tex2', { y: 100, opacity: 0 })
gsap.to('#texV', { y: 100, opacity: 0 });
gsap.to('#tex3', { y: 500 });
gsap.to('#tex4', { x: -1000 });
gsap.to('#tex5', { opacity: 0 });
gsap.to('#tex6', { x: 1000 });