import gsap from 'gsap';
import * as THREE from 'three';
import { lerp } from 'three/src/math/MathUtils';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import vertexShaderParticles from '../static/shaders/vertex.glsl';
import fragmentShaderParticles from '../static/shaders/fragment.glsl';

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

// Loaders
const overlay = document.getElementById('overlay');
const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // Wait a little
    window.setTimeout(() => {
      // Animate overlay
      gsap.to(overlay, {
        duration: 0.5,
        y: '-100vh',
        ease: 'power2.inOut',
      });
    }, 1500);
  }
);

const gltfLoader = new GLTFLoader(loadingManager);
const textureLoader = new THREE.TextureLoader(
  loadingManager
);

// Textures
const matcapTexture = textureLoader.load(
  '/textures/vidrio.png'
);
matcapTexture.colorSpace = THREE.SRGBColorSpace;

const matcapTexture2 = textureLoader.load(
  '/textures/azulverde.png'
);
matcapTexture.colorSpace = THREE.SRGBColorSpace;

// Material
const material = new THREE.MeshMatcapMaterial({
  matcap: matcapTexture,
  color: '#FFF',
});
const material2 = new THREE.MeshMatcapMaterial({
  matcap: matcapTexture2,
  color: '#FFF',
  color: 0xffffff,
  transparent: true,
  opacity: 0,
});

// Sizes
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
  color: window.matchMedia('(prefers-color-scheme: dark)')
    .matches,
};

// Position Config
function mapRange(in_min, in_max, input, out_min, out_max) {
  return (
    ((input - in_min) * (out_max - out_min)) /
      (in_max - in_min) +
    out_min
  );
}

const ratio = sizes.width / sizes.height;

// Initial Positions
const objectsDistance = 0;

const positionH = {
  x:
    sizes.width >= 1000
      ? mapRange(1, 2.5, ratio, 1, 2.5)
      : 0,
  y:
    sizes.width >= 1000
      ? objectsDistance - 1.2
      : objectsDistance - 0.2,
  z: mapRange(0, 2.5, ratio, -6, 0.5),
};

const positionE = {
  x:
    sizes.width >= 1000
      ? mapRange(1, 2.5, ratio, 2.5, 2.8)
      : -mapRange(0, 1, ratio, 0.7, 1.1),
  y:
    sizes.width >= 1000
      ? objectsDistance - 0.12
      : objectsDistance - mapRange(0, 1, ratio, 1.8, 2),
  z: mapRange(0, 2.5, ratio, -6, 0.5),
};

const positionC = {
  x:
    sizes.width >= 1000
      ? -mapRange(1, 2.5, ratio, 2.5, 2.8)
      : mapRange(0, 1, ratio, 0.7, 1.1),
  y:
    sizes.width >= 1000
      ? objectsDistance - 0.3
      : objectsDistance - mapRange(0, 1, ratio, 0.9, 1.2),
  z: mapRange(0, 2.5, ratio, -6, 0.5),
};

// Model Huntek Config
let modelHuntek = null;
gltfLoader.load('./models/TRY.glb', (gltf) => {
  modelHuntek = gltf.scene;
  modelHuntek.scale.set(0.3, 0.3, 0.3);
  modelHuntek.position.x = positionH.x;
  modelHuntek.position.y = positionH.y;
  modelHuntek.position.z = positionH.z;
  modelHuntek.traverse((child) => {
    if (child.isMesh) child.material = material;
  });

  scene.add(modelHuntek);
});

// Model Empresa Config
let modelEmp = null;
gltfLoader.load('./models/briefcase.glb', (gltf) => {
  modelEmp = gltf.scene;
  modelEmp.scale.set(0.25, 0.25, 0.25);
  modelEmp.position.x = positionE.x;
  modelEmp.position.y = positionE.y;
  modelEmp.position.z = positionE.z;
  modelEmp.traverse((child) => {
    if (child.isMesh) child.material = material2;
  });

  scene.add(modelEmp);
});

// Model Candidato Config
let modelCan = null;
gltfLoader.load('./models/user_3d_icon.glb', (gltf) => {
  modelCan = gltf.scene;
  modelCan.scale.set(0.45, 0.45, 0.45);
  modelCan.position.x = positionC.x;
  modelCan.position.y = positionC.y;
  modelCan.position.z = positionC.z;
  modelCan.traverse((child) => {
    if (child.isMesh) child.material = material2;
  });

  scene.add(modelCan);
});

let fireflies; // Mueve la declaración de 'fireflies' fuera de la función
let firefliesMaterial = null; // Mueve la declaración de 'firefliesMaterial' fuera de la función

function createFireflies() {
  if (
    fireflies &&
    currentSection !== 4 &&
    lastSection !== 4
  )
    return;

  // Si 'fireflies' existe y estamos en la sección 5, lo eliminamos de la escena
  if (fireflies) scene.remove(fireflies);

  const firefliesGeometry = new THREE.BufferGeometry();
  let firefliesCount = 60;
  if (currentSection === 4)
    firefliesCount = 400; // aumenta la cantidad de partículas en la sección 5
  else if (currentSection !== 4 && firefliesCount !== 60)
    firefliesCount = 60; // restablece la cantidad de partículas a 60 en las otras secciones

  const positionArray = new Float32Array(
    firefliesCount * 3
  );
  const scaleArray = new Float32Array(firefliesCount);

  for (let i = 0; i < firefliesCount; i++) {
    let rangeX = currentSection === 4 ? 7.2 : 1.8;
    let rangeY = currentSection === 4 ? 8 : 2;
    let rangeZ = currentSection === 4 ? 8 : 4;
    positionArray[i * 3 + 0] =
      (Math.random() - 0.5) * rangeX + 1;
    positionArray[i * 3 + 1] = Math.random() * rangeY - 1.2;
    positionArray[i * 3 + 2] =
      (Math.random() - 0.5) * rangeZ;

    scaleArray[i] = Math.random();
  }
  firefliesGeometry.setAttribute(
    'position',
    new THREE.BufferAttribute(positionArray, 3)
  );
  firefliesGeometry.setAttribute(
    'aScale',
    new THREE.BufferAttribute(scaleArray, 1)
  );

  firefliesMaterial = new THREE.ShaderMaterial({
    uniforms: {
      uTime: { value: 0 },
      uSize: { value: 0 },
      uRightSide: { value: 1 },
      uPixelRatio: {
        value: Math.min(window.devicePixelRatio, 2),
      },
      uColorStart: { value: new THREE.Color('#046255') },
      uColorEnd: { value: new THREE.Color('#046255') },
    },
    fragmentShader: fragmentShaderParticles,
    vertexShader: vertexShaderParticles,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
  });

  fireflies = new THREE.Points(
    firefliesGeometry,
    firefliesMaterial
  );
  scene.add(fireflies);
  lastSection = currentSection;
}

// Renderer
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(
  Math.min(window.devicePixelRatio, 2)
);
renderer.setClearColor(0xffffff, 0);
renderer.toneMapping = THREE.ReinhardToneMapping;
renderer.toneMappingExposure = 6;

// Camera Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.z = 6;
cameraGroup.add(camera);

// Cursor
const cursor = {};
cursor.x = 0;
cursor.y = 0;

window.addEventListener('mousemove', (event) => {
  cursor.x = event.clientX / sizes.width - 0.5;
  cursor.y = event.clientY / sizes.height - 0.5;
});

// Animate
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const deltaTime = elapsedTime - previousTime;
  previousTime = elapsedTime;
  if (firefliesMaterial !== null)
    fireflies.material.uniforms.uTime.value = elapsedTime;

  // Animate camera
  camera.position.y =
    (-scrollY / sizes.height) * objectsDistance;

  const parallaxX = cursor.x * 0.5;
  const parallaxY = -cursor.y * 0.5;

  cameraGroup.position.x +=
    (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
  cameraGroup.position.y +=
    (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

  // Animate meshes
  if (modelHuntek) {
    modelHuntek.rotation.x += Math.cos(elapsedTime) * 0.003;
    modelHuntek.rotation.y += deltaTime * 0.9;
  }

  if (modelEmp) {
    modelEmp.rotation.x += Math.cos(elapsedTime) * 0.003;
    modelEmp.rotation.y -= deltaTime * 0.9;
  }
  if (modelCan) {
    modelCan.rotation.x += Math.cos(elapsedTime) * 0.003;
    modelCan.rotation.y -= deltaTime * 0.9;
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

// Variables
let intialPosition = positionH.x;
let invertPosition = -intialPosition;
let scrollHeight = window.scrollY / sizes.height;
let currentSection = 0;
let newSection = 0;
let lastSection = currentSection;
const color1 = new THREE.Color('#FFFFFF');
const color2 = new THREE.Color('#046255');

// Resize Config
window.addEventListener('resize', () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  const ratio = sizes.width / sizes.height;

  // Update camera
  camera.aspect = ratio;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(
    Math.min(window.devicePixelRatio, 2)
  );

  // Update FireFlies
  if (firefliesMaterial !== null)
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(
      window.devicePixelRatio,
      2
    );

  modelHuntek.position.x =
    sizes.width >= 1000
      ? mapRange(1, 2.5, ratio, 1, 2.5)
      : 0;
  modelHuntek.position.y =
    sizes.width >= 1000
      ? objectsDistance - 1.2
      : objectsDistance - 0.2;
  modelHuntek.position.z = mapRange(0, 2.5, ratio, -6, 0.5);

  intialPosition = modelHuntek.position.x;
  invertPosition = -intialPosition;

  modelEmp.position.x =
    sizes.width >= 1000
      ? mapRange(1, 2.5, ratio, 2.5, 2.8)
      : -mapRange(0, 1, ratio, 0.7, 1.1);
  modelEmp.position.y =
    sizes.width >= 1000
      ? objectsDistance - 0.12
      : objectsDistance - mapRange(0, 1, ratio, 1.8, 2);
  modelEmp.position.z = mapRange(0, 2.5, ratio, -6, 0.5);

  modelCan.position.x =
    sizes.width >= 1000
      ? -mapRange(1, 2.5, ratio, 2.5, 2.8)
      : mapRange(0, 1, ratio, 0.7, 1.1);
  modelCan.position.y =
    sizes.width >= 1000
      ? objectsDistance - 0.3
      : objectsDistance - mapRange(0, 1, ratio, 0.5, 0.21);
  modelCan.position.z = mapRange(0, 2.5, ratio, -6, 0.5);
});

// Scroll Animations
window.addEventListener('scroll', () => {
  scrollHeight = window.scrollY / sizes.height;
  newSection = Math.round(window.scrollY / sizes.height);

  // Movement H
  let positionX = lerp(
    1,
    -1,
    scrollHeight <= 1
      ? scrollHeight
      : scrollHeight <= 2
      ? scrollHeight - 1
      : scrollHeight <= 3
      ? scrollHeight - 2
      : scrollHeight <= 4
      ? scrollHeight - 3
      : scrollHeight - 4
  );
  let centerX = lerp(
    1,
    0,
    scrollHeight <= 1
      ? scrollHeight
      : scrollHeight <= 2
      ? scrollHeight - 1
      : scrollHeight <= 3
      ? scrollHeight - 2
      : scrollHeight <= 4
      ? scrollHeight - 3
      : scrollHeight - 4
  );
  let positionY = lerp(
    0,
    1,
    scrollHeight <= 1
      ? scrollHeight
      : scrollHeight <= 2
      ? scrollHeight - 1
      : scrollHeight <= 3
      ? scrollHeight - 2
      : scrollHeight <= 4
      ? scrollHeight - 3
      : scrollHeight - 4
  );

  if (scrollHeight === 0) {
    positionH.x = intialPosition * positionX;
    positionH.y = objectsDistance - 0.2;
  }
  if (scrollHeight > 0 && scrollHeight <= 1) {
    positionH.x = intialPosition * positionX;
    positionH.y = objectsDistance - 0.2 - 1 * positionY;
  }
  if (scrollHeight > 1 && scrollHeight <= 2) {
    positionH.x = invertPosition * positionX;
    positionH.y = objectsDistance - 1.2 - 1.2 * positionY;
    sizes.width < 1000 &&
      (modelHuntek.position.z = mapRange(
        0,
        2.5,
        ratio,
        -6,
        1
      ));
  }
  if (scrollHeight > 2 && scrollHeight <= 3) {
    positionH.x = intialPosition * positionX;
    sizes.width >= 1000 &&
      (modelHuntek.position.z = mapRange(
        0,
        2.5,
        ratio,
        -6,
        0.5
      ));
    sizes.width < 1000 &&
      (modelHuntek.position.z =
        mapRange(0, 2.5, ratio, -6, 1) - 100 * positionY);
  }
  if (scrollHeight > 3 && scrollHeight <= 4) {
    positionH.x = invertPosition * centerX;
    sizes.width >= 1000 &&
      (modelHuntek.position.z =
        mapRange(0, 2.5, ratio, -6, 0.5) - 3 * positionY);
    sizes.width < 1000 &&
      (modelHuntek.position.z =
        mapRange(0, 2.5, ratio, -6, 1) - 100);
  }
  if (scrollHeight > 4 && scrollHeight <= 5) {
    positionH.x = invertPosition * (1 + centerX);
    positionH.y = objectsDistance - 2.4 + 2.8 * positionY;
    sizes.width < 1000 &&
      (modelHuntek.position.z =
        mapRange(0, 2.5, ratio, -6, 1) -
        100 +
        97 * positionY);
  }

  modelHuntek.position.x =
    sizes.width >= 1000 && positionH.x;
  sizes.width < 1000 &&
    (modelHuntek.position.y = positionH.y);

  if (firefliesMaterial !== null)
    firefliesMaterial.uniforms.uRightSide.value =
      positionH.x - 1;

  // Animations
  if (scrollHeight > 0.99 && scrollHeight < 1.01)
    gsap.to('#tex1', { y: 0, opacity: 1 });
  if (scrollHeight > 1.99 && scrollHeight < 2.01) {
    sizes.width >= 1000
      ? gsap.to('#tex2', { y: 0, opacity: 1 })
      : gsap.to('#texV', { y: 0, opacity: 1 });
  }
  if (scrollHeight > 2.99 && scrollHeight < 3.01) {
    sizes.width >= 1000
      ? gsap.to('#tex3', { y: 0, opacity: 1 })
      : gsap.to('#tex2', { y: 0, opacity: 1 });
  }
  if (scrollHeight > 3.99 && scrollHeight < 4.01) {
    if (sizes.width >= 1000) {
      gsap.to('#tex5', { opacity: 1 });
      gsap.to('#tex4', { x: 0 });
      gsap.to('#tex6', { x: 0 });
      gsap.to(material2, { opacity: 1 });
    } else gsap.to('#tex3', { y: 0, opacity: 1 });
  } else {
    if (sizes.width >= 1000) {
      gsap.to(material2, { opacity: 0 });
      gsap.to('#tex5', { opacity: 0 });
      gsap.to('#tex4', { x: -1000 });
      gsap.to('#tex6', { x: 1000 });
    }
  }
  if (scrollHeight > 4.99 && scrollHeight < 5.01) {
    gsap.to('#tex5', { opacity: 1 });
    gsap.to('#tex4', { x: 0 });
    gsap.to('#tex6', { x: 0 });
    gsap.to(material2, { opacity: 1 });
  }

  if (newSection != currentSection) {
    currentSection = newSection;

    createFireflies();

    if (currentSection !== 1) {
      if (currentSection < 1)
        gsap.to('#tex1', 0.1, {
          opacity: 0,
          onComplete: () => {
            gsap.to('#tex1', { y: 100 });
          },
        });
      else if (currentSection > 1)
        gsap.to('#tex1', 0.1, {
          opacity: 0,
          onComplete: () => {
            gsap.to('#tex1', { y: -100 });
          },
        });
    }

    if (sizes.width >= 1000) {
      if (currentSection !== 2) {
        if (currentSection < 2)
          gsap.to('#tex2', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex2', { y: 100 });
            },
          });
        else if (currentSection > 2)
          gsap.to('#tex2', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex2', { y: -100 });
            },
          });
      }
      if (currentSection !== 3) {
        if (currentSection < 3)
          gsap.to('#tex3', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex3', { y: 100 });
            },
          });
        else if (currentSection > 3)
          gsap.to('#tex3', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex3', { y: -100 });
            },
          });
      }
    } else {
      if (currentSection !== 2) {
        if (currentSection < 2)
          gsap.to('#texV', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#texV', { y: 100 });
            },
          });
        else if (currentSection > 2)
          gsap.to('#texV', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#texV', { y: -100 });
            },
          });
      }
      if (currentSection !== 3) {
        if (currentSection < 3)
          gsap.to('#tex2', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex2', { y: 100 });
            },
          });
        else if (currentSection > 3)
          gsap.to('#tex2', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex2', { y: -100 });
            },
          });
      }
      if (currentSection !== 4) {
        if (currentSection < 4)
          gsap.to('#tex3', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex3', { y: 100 });
            },
          });
        else if (currentSection > 4)
          gsap.to('#tex3', 0.1, {
            opacity: 0,
            onComplete: () => {
              gsap.to('#tex3', { y: -100 });
            },
          });
      }
      if (currentSection !== 5) {
        gsap.to(material2, { opacity: 0 });
        gsap.to('#tex5', { opacity: 0 });
        gsap.to('#tex4', { x: -1000 });
        gsap.to('#tex6', { x: 1000 });
      }
    }
  }

  // Particles
  if (firefliesMaterial !== null && sizes.width >= 1000) {
    if (
      (currentSection && currentSection === 1) ||
      currentSection === 2 ||
      currentSection === 4
    ) {
      gsap.to(fireflies.material.uniforms.uSize, {
        duration: 0.5,
        ease: 'power2.inOut',
        value: 250,
      });
    } else {
      gsap.to(fireflies.material.uniforms.uSize, {
        duration: 0.15,
        ease: 'power2.inOut',
        value: 0,
      });
    }
  }

  // H Color
  if (sizes.width >= 1000) {
    if (currentSection !== 0 && currentSection !== 3)
      material.color.lerp(color2, 0.5);
    else material.color.lerp(color1, 0.1);
  } else {
    if (currentSection !== 0 && currentSection !== 4)
      material.color.lerp(color2, 0.4);
    else material.color.lerp(color1, 0.1);
  }

  // H Index
  if (scrollHeight % 1 !== 0 || scrollHeight > 3) {
    document.getElementById('canvas').style.zIndex = '1';
  } else
    document.getElementById('canvas').style.zIndex = '0';

  // Nav Colors
  if (
    scrollHeight < 0.95 ||
    (sizes.width >= 1000 && scrollHeight > 2.95) ||
    (sizes.width < 1000 && scrollHeight > 3.95)
  ) {
    // sizes.color = window.matchMedia(
    //   '(prefers-color-scheme: dark)'
    // ).matches;
    // !sizes.color &&
    //   (document.getElementById('nav').style.color =
    //     '#FFFFFF');
    document.getElementById('nav').style.color = '#FFFFFF';
    document
      .getElementById('huntekw')
      .classList.remove('inactive');
    document
      .getElementById('huntek')
      .classList.remove('active');
    document
      .getElementById('huntekw')
      .classList.add('active');
    document
      .getElementById('huntek')
      .classList.add('inactive');
    document.getElementById(
      'inicio'
    ).style.backgroundColor = '#FFFFFF';
    document.getElementById('nos1').style.backgroundColor =
      '#FFFFFF';
    document.getElementById('bene').style.backgroundColor =
      '#FFFFFF';
    document.getElementById('regis').style.backgroundColor =
      '#FFFFFF';
  } else {
    // sizes.color = window.matchMedia(
    //   '(prefers-color-scheme: dark)'
    // ).matches;
    // !sizes.color &&
    //   (document.getElementById('nav').style.color =
    //     '#000000');
    document.getElementById('nav').style.color = '#046255';
    document
      .getElementById('huntek')
      .classList.remove('inactive');
    document
      .getElementById('huntekw')
      .classList.remove('active');
    document
      .getElementById('huntek')
      .classList.add('active');
    document
      .getElementById('huntekw')
      .classList.add('inactive');
    document.getElementById(
      'inicio'
    ).style.backgroundColor = '#046255';
    document.getElementById('nos1').style.backgroundColor =
      '#046255';
    document.getElementById('bene').style.backgroundColor =
      '#046255';
    document.getElementById('regis').style.backgroundColor =
      '#046255';
  }

  // Nav Indicator
  if (scrollHeight < 1) {
    document.getElementById('inicio').style.width = `${
      100 - scrollHeight * 100
    }%`;
    if (sizes.width >= 1000)
      document.getElementById(
        'inicio'
      ).style.transform = `translateX(${
        scrollHeight * 58
      }px)`;
    else
      document.getElementById(
        'inicio'
      ).style.transform = `translateX(${
        scrollHeight * 48
      }px)`;
  }

  if (scrollHeight > 0 && scrollHeight < 4) {
    if (scrollHeight <= 1) {
      if (sizes.width >= 1000)
        document.getElementById('nos1').style.width = `${
          scrollHeight * 47
        }%`;
      else
        document.getElementById('nos1').style.width = `${
          scrollHeight * 28
        }%`;
      document.getElementById(
        'nos1'
      ).style.transform = `translateX(${0}px)`;
    }
    if (scrollHeight > 1 && scrollHeight <= 2) {
      if (sizes.width >= 1000) {
        document.getElementById('nos1').style.width = `${
          100 - (scrollHeight * 47 + 6)
        }%`;
        document.getElementById(
          'nos1'
        ).style.transform = `translateX(${
          (scrollHeight - 1) * 40
        }px)`;
        document.getElementById('nos3').style.width = `${
          (scrollHeight - 1) * 47
        }%`;
        document.getElementById(
          'nos3'
        ).style.transform = `translateX(${0}px)`;
      } else {
        document.getElementById('nos1').style.width = `${
          100 - (scrollHeight * 28 + 44)
        }%`;
        document.getElementById(
          'nos1'
        ).style.transform = `translateX(${
          (scrollHeight - 1) * 24.2
        }px)`;
        document.getElementById('nos2').style.width = `${
          (scrollHeight - 1) * 28
        }%`;
        document.getElementById(
          'nos2'
        ).style.transform = `translateX(${0}px)`;
      }
    }
    if (scrollHeight > 2 && scrollHeight <= 3) {
      if (sizes.width >= 1000) {
        document.getElementById('nos3').style.width = `${
          100 - ((scrollHeight - 1) * 47 + 6)
        }%`;
        document.getElementById(
          'nos3'
        ).style.transform = `translateX(${
          (scrollHeight - 2) * 40
        }px)`;
        document.getElementById('bene').style.width = `${
          scrollHeight * 100 - 200
        }%`;
        document.getElementById(
          'bene'
        ).style.transform = `translateX(${0}px)`;
      } else {
        document.getElementById('nos2').style.width = `${
          100 - ((scrollHeight - 1) * 28 + 44)
        }%`;
        document.getElementById(
          'nos2'
        ).style.transform = `translateX(${
          (scrollHeight - 2) * 24.2
        }px)`;
        document.getElementById('nos3').style.width = `${
          (scrollHeight - 2) * 28
        }%`;
        document.getElementById(
          'nos3'
        ).style.transform = `translateX(${0}px)`;
      }
    }
    if (scrollHeight > 3) {
      if (sizes.width >= 1000) {
        document.getElementById('bene').style.width = `${
          100 - (scrollHeight * 100 - 300)
        }%`;
        document.getElementById(
          'bene'
        ).style.transform = `translateX(${
          (scrollHeight - 3) * 97
        }px)`;
      } else {
        document.getElementById('nos3').style.width = `${
          100 - ((scrollHeight - 2) * 28 + 44)
        }%`;
        document.getElementById(
          'nos3'
        ).style.transform = `translateX(${
          (scrollHeight - 3) * 24.2
        }px)`;
      }
    }
  }

  if (scrollHeight > 3 && scrollHeight < 5) {
    if (scrollHeight <= 4) {
      if (sizes.width >= 1000) {
        document.getElementById('regis').style.width = `${
          scrollHeight * 100 - 300
        }%`;
      } else {
        document.getElementById('bene').style.width = `${
          scrollHeight * 100 - 300
        }%`;
        document.getElementById(
          'bene'
        ).style.transform = `translateX(${0}px)`;
      }
    }
    if (scrollHeight > 4) {
      if (sizes.width < 1000) {
        document.getElementById('bene').style.width = `${
          100 - (scrollHeight * 100 - 400)
        }%`;
        document.getElementById(
          'bene'
        ).style.transform = `translateX(${
          (scrollHeight - 4) * 97
        }px)`;
      }
    }
  }

  if (scrollHeight > 3) {
    if (sizes.width >= 1000)
      document.getElementById('regis').style.width = `${
        scrollHeight * 100 - 300
      }%`;
    else
      document.getElementById('regis').style.width = `${
        scrollHeight * 100 - 400
      }%`;
  }

  if (sizes.width >= 1000) {
    if (scrollHeight >= 1)
      document.getElementById('inicio').style.width = `0%`;
    if (scrollHeight === 0 || scrollHeight > 2)
      document.getElementById('nos1').style.width = `0%`;
    if (scrollHeight <= 1 || scrollHeight > 3)
      document.getElementById('nos3').style.width = `0%`;
    if (scrollHeight <= 2 || scrollHeight === 4)
      document.getElementById('bene').style.width = `0%`;
    if (scrollHeight <= 3)
      document.getElementById('regis').style.width = `0%`;
    if (scrollHeight > 0 && scrollHeight < 3) {
      document.getElementById(
        'alt1'
      ).style.backgroundColor = '#04625540';
      document.getElementById(
        'alt3'
      ).style.backgroundColor = '#04625540';
    } else {
      document.getElementById(
        'alt1'
      ).style.backgroundColor = '#04625500';
      document.getElementById(
        'alt3'
      ).style.backgroundColor = '#04625500';
    }
  } else {
    if (scrollHeight >= 1)
      document.getElementById('inicio').style.width = `0%`;
    if (scrollHeight > 2)
      document.getElementById('nos1').style.width = `0%`;
    if (scrollHeight <= 1 || scrollHeight > 3)
      document.getElementById('nos2').style.width = `0%`;
    if (scrollHeight <= 2 || scrollHeight > 4)
      document.getElementById('nos3').style.width = `0%`;
    if (scrollHeight <= 3 || scrollHeight > 5)
      document.getElementById('bene').style.width = `0%`;
    if (scrollHeight <= 4)
      document.getElementById('regis').style.width = `0%`;
    if (scrollHeight > 0 && scrollHeight < 4) {
      document.getElementById(
        'alt1'
      ).style.backgroundColor = '#04625540';
      document.getElementById(
        'alt2'
      ).style.backgroundColor = '#04625540';
      document.getElementById(
        'alt3'
      ).style.backgroundColor = '#04625540';
    } else {
      document.getElementById(
        'alt1'
      ).style.backgroundColor = '#04625500';
      document.getElementById(
        'alt2'
      ).style.backgroundColor = '#04625500';
      document.getElementById(
        'alt3'
      ).style.backgroundColor = '#04625500';
    }
  }
});
